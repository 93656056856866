/* tslint:disable */
/* eslint-disable */
/**
 * The Thing API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AssignedAttorney,
    AssignedAttorneyFromJSON,
    AssignedAttorneyFromJSONTyped,
    AssignedAttorneyToJSON,
    ContactLanguages,
    ContactLanguagesFromJSON,
    ContactLanguagesFromJSONTyped,
    ContactLanguagesToJSON,
    ExternalIntegration,
    ExternalIntegrationFromJSON,
    ExternalIntegrationFromJSONTyped,
    ExternalIntegrationToJSON,
    ExternalPartners
} from './';

/**
 * 
 * @export
 * @interface Client
 */
export interface Client {
    /**
     * 
     * @type {string}
     * @memberof Client
     */
    contactFirstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Client
     */
    contactLastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Client
     */
    contactEmail?: string | null;
    /**
     * 
     * @type {ContactLanguages}
     * @memberof Client
     */
    contactLanguage?: ContactLanguages;
    /**
     * 
     * @type {Array<AssignedAttorney>}
     * @memberof Client
     */
    attorneys?: Array<AssignedAttorney> | null;
    /**
     * 
     * @type {string}
     * @memberof Client
     */
    id?: string | undefined;
    /**
     * 
     * @type {string}
     * @memberof Client
     */
    firmId?: string;
    /**
     * 
     * @type {string}
     * @memberof Client
     */
    name?: string | null;
    /**
     * 
     * @type {ExternalPartners}
     * @memberof ExternalClient
     */
    partner?: ExternalPartners;
    /**
     * 
     * @type {string}
     * @memberof Client
     */
    aliases?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Client
     */
    respondentName?: string | null;
    /**
     * 
     * @type {Set<string>}
     * @memberof Client
     */
    cases?: Set<string> | null;
    /**
     * 
     * @type {Date}
     * @memberof Client
     */
    lastActivity?: Date | null;
    /**
     * 
     * @type {ExternalIntegration}
     * @memberof Client
     */
    integration?: ExternalIntegration;
}

export function ClientFromJSON(json: any): Client {
    return ClientFromJSONTyped(json, false);
}

export function ClientFromJSONTyped(json: any, ignoreDiscriminator: boolean): Client {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'contactFirstName': !exists(json, 'contactFirstName') ? undefined : json['contactFirstName'],
        'contactLastName': !exists(json, 'contactLastName') ? undefined : json['contactLastName'],
        'contactEmail': !exists(json, 'contactEmail') ? undefined : json['contactEmail'],
        'contactLanguage': !exists(json, 'contactLanguage') ? undefined : ContactLanguagesFromJSON(json['contactLanguage']),
        'attorneys': !exists(json, 'attorneys') ? undefined : (json['attorneys'] === null ? null : (json['attorneys'] as Array<any>).map(AssignedAttorneyFromJSON)),
        'id': !exists(json, 'id') ? undefined : json['id'],
        'firmId': !exists(json, 'firmId') ? undefined : json['firmId'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'aliases': !exists(json, 'aliases') ? undefined : json['aliases'],
        'respondentName': !exists(json, 'respondentName') ? undefined : json['respondentName'],
        'cases': !exists(json, 'cases') ? undefined : json['cases'],
        'lastActivity': !exists(json, 'lastActivity') ? undefined : (json['lastActivity'] === null ? null : new Date(json['lastActivity'])),
        'integration': !exists(json, 'integration') ? undefined : ExternalIntegrationFromJSON(json['integration']),
    };
}

export function ClientToJSON(value?: Client | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'contactFirstName': value.contactFirstName,
        'contactLastName': value.contactLastName,
        'contactEmail': value.contactEmail,
        'contactLanguage': ContactLanguagesToJSON(value.contactLanguage),
        'attorneys': value.attorneys === undefined ? undefined : (value.attorneys === null ? null : (value.attorneys as Array<any>).map(AssignedAttorneyToJSON)),
        'id': value.id,
        'firmId': value.firmId,
        'name': value.name,
        'aliases': value.aliases,
        'respondentName': value.respondentName,
        'cases': value.cases,
        'lastActivity': value.lastActivity === undefined ? undefined : (value.lastActivity === null ? null : value.lastActivity.toISOString()),
        'integration': ExternalIntegrationToJSON(value.integration),
    };
}


