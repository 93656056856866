import { Menu, MenuItem, RenderMenuProps, Typeahead } from 'react-bootstrap-typeahead';
import { Jurisdiction } from 'briefpoint-client/models/Jurisdiction';
import React from 'react';
import "./FederalTypeahead.scss";
import { Option } from 'react-bootstrap-typeahead/types/types';
import { Venue } from 'briefpoint-client';

interface Props {
  setVenue: (selected: Option[]) => void
  venue: string
  jurisdiction: Jurisdiction | undefined
  jurisdictions: Jurisdiction[] | undefined;
  typeaheadRef: React.MutableRefObject<any>
  officeJxIds?: string[]
}

export default function FederalTypeahead(props: Props) {
  const { setVenue, venue, jurisdiction, jurisdictions, typeaheadRef, officeJxIds } = props;

  const mapIdToJxName = (jxId: string) => {
    if(!jurisdictions){
      return "";
    }

    var jx = jurisdictions.find(j => j.id === jxId);
    return jx?.friendlyName?.toUpperCase();
  }

  function unique(value: any, index: any, array: string | any[]) {
    return array.indexOf(value) === index;
  }

  const sortVenues = (a: Venue, b: Venue) => {
    if (officeJxIds) {
      var aCon = officeJxIds.includes(a.relatedJurisdictionId ?? "");
      var bCon = officeJxIds.includes(b.relatedJurisdictionId ?? "");

      if (aCon && !bCon) {
        return -1;
      }
      else if (bCon && !aCon) {
        return 1;
      }
    }
    var aJxName = mapIdToJxName(a.relatedJurisdictionId ?? "") ?? "";
    var bJxName = mapIdToJxName(b.relatedJurisdictionId ?? "") ?? "";
    var lcJx = aJxName?.localeCompare(bJxName);

    if (lcJx !== 0) {
      return lcJx;
    }

    return a.shortName?.localeCompare(b.shortName ?? "") ?? 0;
  }


  const renderFederalMenu = (results: any[], menuProps: RenderMenuProps) => {
    const { newSelectionPrefix, paginationText, renderMenuItemChildren, ...mProps } = menuProps; // filter props to clear warning
    var relJXIds = results.flatMap(r => r.relatedJurisdictionId).filter(unique) as string[];
    var sortedResults = results.sort(sortVenues);

    // Used to track the current position the user has selected. Accessibility fix when navigating via up/down keys 
    let positionCounter = 0;

    return (
      <Menu {...mProps}>
        {relJXIds.map((group) => {
          const groupResults = sortedResults.filter(r => r.relatedJurisdictionId === group);

          return (
            <div key={`group-${group}`}>
              <div className='typeahead-group-header'>{mapIdToJxName(group)}</div>
              {groupResults.map((result) => {
                const currentPosition = positionCounter++;
                return (
                  <MenuItem
                    option={result}
                    key={`${group}-${result.id}`}
                    position={currentPosition} // NOTE: Make sure this is correct or you will get an infinite render error
                  >
                    {result.shortName}
                  </MenuItem>
                );
              })}
            </div>
          );
        })}
      </Menu>
    );
  };

  return (
    <Typeahead
      onChange={setVenue}
      selected={(venue && [jurisdiction?.venues!.find(x => x.id === venue)].filter((x): x is NonNullable<typeof x> => x !== undefined)) || []}
      isInvalid={!venue}
      ref={typeaheadRef}
      renderMenu={renderFederalMenu}
      id="venue-select"
      options={jurisdiction?.venues || []}
      placeholder="Select a Venue..."
      labelKey={(o: any) => `${o.shortName}`} />
  );
}
