import { Case, CommunicationStatus, CommunicationSource, CredentialResult, DocumentSubType, ViewCommunication, Client, Source } from 'briefpoint-client';
import { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import { DocumentClient, GetDocumentSubTypeShortName } from '../../services/DocumentService';
import { formatShortDate } from '../../utils/dateUtils';
import DocumentActionsMenu from './DocumentActionsMenu';
import { DocumentIcon, formatLocalDate, DueSoon, NewComm } from './DocumentList';
import React from 'react';
import { ChevronDown, PencilFill } from 'react-bootstrap-icons';
import './DocumentRowWithReview.scss';
import LinkAndCodeModal from 'components/Modals/LinkandCodeModal';
import ReminderEmailModal from 'components/Modals/ReminderEmailModal';
import { useCredentialApi } from 'hooks/useApi';
import StartOverWarningModal from 'components/Modals/StartOverWarning';
import { Action } from 'components/CaseManagement/DocumentsTable';
import { Link } from 'react-router-dom';
import { useAuth } from 'hooks/useAuth';
import useConfig, { MULTI_CLIENT_FF } from 'hooks/useConfig';
import ConfirmCodeResetModal from 'components/Modals/ConfirmCodeReset';

export function DocumentRowWithReviewClient({
  doc, _case, archiveDocument, editDueDate, setShowSetCase, downloadForm, downloadRequest, createReview, client, communication, removeClientAccess, reopenCommunication, propoundingParty, respondingParty, updateDocumentName
}: {
  doc: DocumentClient;
  _case?: Case;
  archiveDocument: (document: DocumentClient) => Promise<void>;
  editDueDate?: Dispatch<SetStateAction<DocumentClient | undefined>>;
  setShowSetCase?: Dispatch<SetStateAction<DocumentClient | undefined>>;
  downloadForm: (document: DocumentClient, formId: string, formTypeName: string) => Promise<void>;
  downloadRequest: (document: DocumentClient) => Promise<void>;
  createReview?: (document: DocumentClient) => void;
  client?: Client;
  communication?: ViewCommunication;
  removeClientAccess: (communication: ViewCommunication) => Promise<void>;
  reopenCommunication: (communication: ViewCommunication) => Promise<void>;
  propoundingParty: string;
  respondingParty: string;
  updateDocumentName: (newName: string) => Promise<void>;
}) {
  const showReminderAfterDays = 5;
  const [reviewExpanded, setReviewExpanded] = useState(false);
  const [showLinkModal, setShowLinkModal] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [selectedClient, setShowSelectedClient] = useState<Client>();
  const [showStartOverModal, setShowStartOverModal] = useState(false);
  const [isHoveringName, setIsHoveringName] = useState(false);
  const [isEditingName, setIsEditingName] = useState(false);
  const credentialApi = useCredentialApi();
  const [credential, setCredential] = useState<CredentialResult | undefined>();
  const [showCodeChangeModal, setShowCodeChangeModal] = useState(false);
  const [showNewCodeSuccess, setShowNewCodeSuccess] = useState(false);

  const pdfExtensionReplace = (name: string | undefined | null) => name?.replace('.pdf', '').replace('.PDF', '') ?? "";
  const [docName, setDocName] = useState(pdfExtensionReplace(doc.name));
  const [oldDocName, setOldDocName] = useState(pdfExtensionReplace(doc.name));
  const inputRef = useRef<HTMLInputElement>(null);

  const { user, firm } = useAuth()!;
  const [, featureFlags] = useConfig();
  const useMultiClient = featureFlags()[MULTI_CLIENT_FF] ? featureFlags()[MULTI_CLIENT_FF](user, firm) : false;

  const handlePencilClick = () => {
    setIsEditingName(true);
    inputRef.current?.focus();
  };

  const handleInputBlur = async () => {
    if (isEditingName) {
      setIsEditingName(false);
      if (docName !== oldDocName) {
        await updateDocumentName(docName);
        setOldDocName(docName);
      }
    }
  };

  const handleInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      if (isEditingName) {
        inputRef.current?.blur();
      }
      else {
        setIsEditingName(true);//allow keyboard usage
      }

    }
    if (event.key === 'Escape') {
      setDocName(oldDocName);
      setTimeout(() => { //add timeout so that the change can propagate
        inputRef.current?.blur();
      }, 10);
    }
  };

  const loadCreds = useCallback(async (clientId: string) => {
    if (clientId) {
      const creds = await credentialApi.credentialCreate({ clientId: clientId });
      setCredential(creds);
    }
  }, [credentialApi]);

  useEffect(() => {
    if (communication?.sharedWithObjectId && (reviewExpanded || showEmailModal) && !credential) {
      loadCreds(communication?.sharedWithObjectId);
    }
  }, [communication?.sharedWithObjectId, credential, loadCreds, reviewExpanded, showEmailModal]);

  function getDaysPassed(timeSince: Date | undefined | null): number {
    if (!timeSince) {
      return -1;
    }
    const timeDiff = Math.abs(Date.now() - timeSince.getTime());
    const daysPassed = Math.ceil(timeDiff / (1000 * 3600 * 24));
    return daysPassed;
  }

  function sendReminder() {
    setShowEmailModal(true);
    setShowSelectedClient(client);
  }

  function sendReminderClose() {
    setShowEmailModal(false);
    setShowSelectedClient(undefined);
  }

  function handleLinkAndCodeClose(showChangeCode: boolean) {
    setShowLinkModal(false)
    if (showChangeCode) {
      setShowCodeChangeModal(true);
    }
  }
  function handleCodeChangeClose(goBack: boolean) {
    setShowCodeChangeModal(false)
    if (goBack) {
      setShowLinkModal(true);
    }
  }

  async function handleConfirmCodeChange() {
    const clientId = client?.id ?? communication?.sharedWithObjectId;
    if (!clientId) {
      return
    }
    setShowCodeChangeModal(false);
    setShowLinkModal(true);
    var newCreds = await credentialApi.credentialUpdate({ clientId: clientId });
    setCredential(newCreds);
    setShowNewCodeSuccess(true);
  }

  function getReviewText(communication: ViewCommunication | undefined): string {
    if (!communication) {
      return "";
    }

    const formatDate = (date: Date | null | undefined): string => {
      if (!date) {
        return "";
      }
      const month = date.getMonth() + 1;
      const day = date.getDate();
      return `${month}/${day}`;
    };

    switch (communication.status) {
      case CommunicationStatus.Sent:
        return `Review Requested ${formatDate(communication.lastActivityDate)}`;
      case CommunicationStatus.Open:
        return `Document Opened ${formatDate(communication.lastActivityDate)}`;
      case CommunicationStatus.InProgress:
        return `New Response ${formatDate(communication.lastActivityDate)}`;
      case CommunicationStatus.ChangesRequested:
        return `Changes Requested on ${formatDate(communication.lastActivityDate)}`;
      case CommunicationStatus.Complete:
        return `Review Completed ${formatDate(communication.lastActivityDate)}`;
      case CommunicationStatus.Pending:
        return `Draft Started ${formatDate(communication.lastActivityDate ?? communication.createdDate)}`;
      default:
        return "";
    }
  }

  const viewed = [
    CommunicationStatus.Open,
    CommunicationStatus.InProgress,
    CommunicationStatus.Complete,
    CommunicationStatus.ChangesRequested,
    CommunicationStatus.Closed
  ]

  const dontShowDropDown = [
    CommunicationStatus.Open,
    CommunicationStatus.InProgress,
  ]

  const showReminderEmail = [
    CommunicationStatus.Sent,
    CommunicationStatus.InProgress,
    CommunicationStatus.Open,
  ]

  const timePassed = getDaysPassed(communication?.lastActivityDate);
  let showReviewOption = true;
  if (communication?.status && dontShowDropDown.includes(communication?.status)) {
    showReviewOption = false;
  }
  if (doc?.info?.documentSubType === DocumentSubType.Complaint) {
    showReviewOption = false;
  }

  const clientResponseNumber = communication?.externalCommunicationItems?.filter(ci => ci.source === CommunicationSource.Client)?.length ?? 0;
  const canSendReminderEmail = (communication?.status && showReminderEmail.includes(communication.status)) ?? false;

  let removeBtnText = communication?.status === CommunicationStatus.Pending ? "Delete" : "Start Over";

  return (
    <React.Fragment>
      <ReminderEmailModal
        isShow={showEmailModal}
        onClose={sendReminderClose}
        clientId={communication?.sharedWithObjectId!}
        selectedClient={selectedClient}
      />
      <tr key={doc.id} style={reviewExpanded ? { borderBottom: "hidden" } : {}}>
        <td>
          {doc.source === Source.InternalGenerated ? <DocumentIcon extension="doc" /> : <DocumentIcon extension="pdf" />}
        </td>
        <td className='name'><div
          style={{ display: "flex", justifyContent: "space-between" }}
          onMouseEnter={() => setIsHoveringName(true)}
          onMouseLeave={() => setIsHoveringName(false)}
        >
          <input
            ref={inputRef}
            value={docName}
            onChange={(e) => setDocName(e.target.value)}
            onBlur={handleInputBlur}
            onKeyDown={handleInputKeyDown}
            readOnly={!isEditingName}
            className="form-control-plaintext flex-grow-1 text-truncate"
          />
          {isHoveringName && <PencilFill
            onClick={handlePencilClick}
            className="cursor-pointer"
            style={{ marginTop: "10px" }} />}
        </div>
        </td>
        <td>{!!doc.info?.documentSubType && GetDocumentSubTypeShortName(doc.info.documentSubType, doc.info.jurisdiction ?? '')}</td>
        <td>{formatShortDate(doc.uploadDate)}</td>
        <td>{propoundingParty}</td>
        <td>{respondingParty}</td>
        {editDueDate && <>
          <td>{formatLocalDate(doc.info?.dueDateOverridden || doc.info?.dueDate || undefined) || <Button className='set-dueDate-button' variant='link' onClick={() => editDueDate(doc)}>Set Due Date</Button>}</td>
          <td>{DueSoon(doc.info?.dueDateOverridden || doc.info?.dueDate || undefined)}</td></>
        }
        {useMultiClient &&
          <td> {client ? <Link to={'/clients/' + client.id}>{client.name ?? ""}</Link> : ""}</td>
        }
        <td>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <p style={{ margin: "0px" }}>
              {NewComm(communication, doc)}
              {getReviewText(communication)}{" "}
              {!!communication && (
                <ChevronDown onClick={() => setReviewExpanded(!reviewExpanded)} />
              )}
            </p>
            {timePassed > showReminderAfterDays && canSendReminderEmail && (
              <div className="activity-reminder-wrapper">
                Last activity {timePassed} days ago. <span className="activity-reminder-txt" onClick={sendReminder}> Send client a reminder? </span>
              </div>
            )}
          </div>
        </td>
        <td className='actions-cell' style={{ paddingLeft: "26px" }}>{Action(doc)}</td>
        <td className='kebab-cell'>
          <DocumentActionsMenu
            document={doc}
            _case={_case}
            archiveDocument={archiveDocument}
            editDueDate={editDueDate}
            setShowSetCase={setShowSetCase}
            downloadForm={downloadForm}
            downloadRequest={downloadRequest}
            createReview={showReviewOption ? createReview : undefined}
            sendReminder={canSendReminderEmail ? sendReminder : undefined} />
        </td>
      </tr>
      {reviewExpanded && <tr style={{ borderTop: "none" }}>
        <td colSpan={editDueDate ? 12 : 10}>
          <div className='client-activity-row-container'>
            <div className='client-activity-header'>
              Client Activity Details
              <hr className='client-activity-hr' />
            </div>
            <div className='client-activity-content'>
              <table className='client-activity-table'>
                <thead>
                  <tr>
                    <th className='client-activity-th'>Number of Questions</th>
                    <th className='client-activity-th'>Viewed By Client?</th>
                    <th className='client-activity-th'>Client Answered</th>
                  </tr>
                  <tr className='client-activity-tr'>
                    <td style={{ paddingTop: "0px" }}>{communication?.communicationInfo?.requestedReviewIds?.length}</td>
                    <td style={{ paddingTop: "0px" }}>{communication?.status && viewed.includes(communication.status) ? "Yes" : "No"}</td>
                    <td style={{ paddingTop: "0px" }}>{clientResponseNumber}</td>
                  </tr>
                </thead>
              </table>
              <Button className='remove-client-access-btn'
                onClick={() => {
                  if (clientResponseNumber !== 0) {
                    setShowStartOverModal(true);
                  } else {
                    removeClientAccess(communication!);
                    setReviewExpanded(false);
                  }
                }}>
                {removeBtnText}
              </Button>
              {communication?.status === CommunicationStatus.Complete &&
                (<Button className='remove-client-access-btn'
                  onClick={() => {
                    reopenCommunication(communication);
                  }}>
                  Re-open
                </Button>)
              }
              <StartOverWarningModal
                isShow={showStartOverModal} onClose={
                  (success: boolean) => {
                    if (success) {
                      removeClientAccess(communication!);
                      setReviewExpanded(false);
                    }
                    setShowStartOverModal(false);
                  }}
              />
              {communication?.status === CommunicationStatus.Pending ?
                <Button className='view-link-code-btn'
                  onClick={() => { createReview!(doc) }}>
                  Continue
                </Button>
                :
                <Button className='view-link-code-btn'
                  onClick={() => { setShowLinkModal(true) }}>
                  View Link and Code
                </Button>
              }
              <LinkAndCodeModal
                isShow={showLinkModal}
                onClose={handleLinkAndCodeClose}
                credential={credential}
                showNewCodeSuccess={showNewCodeSuccess}
                client={client}
              />
              <ConfirmCodeResetModal
                isShow={showCodeChangeModal}
                onClose={handleCodeChangeClose}
                onConfirm={handleConfirmCodeChange}
              />
            </div>
          </div>
        </td>
      </tr>}
    </React.Fragment>
  );
}
