import { ContactLanguages, ContentApi, Languages, MiscContent, MiscContentType } from "briefpoint-client";
import { EmailData } from "screens/ClientInfo/SampleEmail";

export const retrieveEmailContent = async (
  contentApi: ContentApi,
  language: ContactLanguages | undefined,
  emailContent: React.Dispatch<React.SetStateAction<MiscContent | undefined>>,
  emailBody?: React.Dispatch<React.SetStateAction<string>>,
  emailSubject?: React.Dispatch<React.SetStateAction<string>>,
): Promise<void> => {

  const emails = await contentApi.contentGetAllMiscContent({ type: MiscContentType.ExternalCommunicationReminderEmail });

  if (emails) {
    let langEmails: MiscContent[];

    langEmails = emails.filter(x => x.language === (language === ContactLanguages.Spanish ? Languages.Spanish : undefined ));

    const email = langEmails.sort((a, b) => a.id!.localeCompare(b.id!))
      .find((e) => !e.name?.localeCompare("User_External_Communication_Email")) || langEmails[0] || emails[0];

    const emailData = JSON.parse(email.content!) as EmailData;

    emailContent(email);
    if (emailBody) emailBody(emailData.body);
    if (emailSubject) emailSubject(emailData.subject);
  }

};
