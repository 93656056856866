/* tslint:disable */
/* eslint-disable */
/**
 * The Thing API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ContactLanguages {
    Unknown = 'Unknown',
    EnglishUs = 'EnglishUS',
    Spanish = 'Spanish'
}

export function ContactLanguagesFromJSON(json: any): ContactLanguages {
    return ContactLanguagesFromJSONTyped(json, false);
}

export function ContactLanguagesFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContactLanguages {
    return json as ContactLanguages;
}

export function ContactLanguagesToJSON(value?: ContactLanguages | null): any {
    return value as any;
}

