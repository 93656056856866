/* tslint:disable */
/* eslint-disable */
/**
 * The Thing API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AssignedAttorney,
    AssignedAttorneyFromJSON,
    AssignedAttorneyFromJSONTyped,
    AssignedAttorneyToJSON,
    CollectionItem,
    CollectionItemFromJSON,
    CollectionItemFromJSONTyped,
    CollectionItemToJSON,
    ContactLanguages,
    ContactLanguagesFromJSON,
    ContactLanguagesFromJSONTyped,
    ContactLanguagesToJSON,
    ExternalIntegration,
    ExternalIntegrationFromJSON,
    ExternalIntegrationFromJSONTyped,
    ExternalIntegrationToJSON,
    Party,
    PartyFromJSON,
    PartyFromJSONTyped,
    PartyToJSON,
    PartyPosition,
    PartyPositionFromJSON,
    PartyPositionFromJSONTyped,
    PartyPositionToJSON,
    Representative,
    RepresentativeFromJSON,
    RepresentativeFromJSONTyped,
    RepresentativeToJSON,
} from './';

/**
 * 
 * @export
 * @interface Case
 */
export interface Case {
    /**
     * 
     * @type {string}
     * @memberof Case
     */
    contactFirstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Case
     */
    contactLastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Case
     */
    contactEmail?: string | null;
    /**
     * 
     * @type {ContactLanguages}
     * @memberof Case
     */
    contactLanguage?: ContactLanguages;
    /**
     * 
     * @type {Array<AssignedAttorney>}
     * @memberof Case
     */
    attorneys?: Array<AssignedAttorney> | null;
    /**
     * 
     * @type {string}
     * @memberof Case
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Case
     */
    clientId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Case
     */
    firmId?: string;
    /**
     * 
     * @type {string}
     * @memberof Case
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Case
     */
    shortTitle?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Case
     */
    caseNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Case
     */
    judge?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Case
     */
    billingCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Case
     */
    jurisdiction?: string;
    /**
     * 
     * @type {string}
     * @memberof Case
     */
    venue?: string;
    /**
     * 
     * @type {string}
     * @memberof Case
     */
    trialDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Case
     */
    filedDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Case
     */
    caseType?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Case
     */
    damages?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Case
     */
    timeline?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Case
     */
    incidentDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Case
     */
    incidentTime?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Case
     */
    incidentLocation?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Case
     */
    incidentDescription?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof Case
     */
    lastActivity?: Date | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof Case
     */
    tagIds?: Array<number> | null;
    /**
     * 
     * @type {Array<Party>}
     * @memberof Case
     */
    otherParties?: Array<Party> | null;
    /**
     * 
     * @type {PartyPosition}
     * @memberof Case
     */
    clientPosition?: PartyPosition;
    /**
     * 
     * @type {Representative}
     * @memberof Case
     */
    representativeType?: Representative;
    /**
     * 
     * @type {string}
     * @memberof Case
     */
    defendants?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Case
     */
    plaintiffs?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Case
     */
    isArchived?: boolean;
    /**
     * 
     * @type {Set<string>}
     * @memberof Case
     */
    clients?: Set<string> | null;
    /**
     * 
     * @type {ExternalIntegration}
     * @memberof Case
     */
    integration?: ExternalIntegration;
    /**
     * 
     * @type {Array<CollectionItem>}
     * @memberof Case
     */
    injuries?: Array<CollectionItem> | null;
    /**
     * 
     * @type {Array<CollectionItem>}
     * @memberof Case
     */
    agreements?: Array<CollectionItem> | null;
    /**
     * 
     * @type {Array<CollectionItem>}
     * @memberof Case
     */
    allegations?: Array<CollectionItem> | null;
    /**
     * 
     * @type {Array<CollectionItem>}
     * @memberof Case
     */
    causesOfAction?: Array<CollectionItem> | null;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof Case
     */
    genericInfo?: { [key: string]: string; } | null;
}

export function CaseFromJSON(json: any): Case {
    return CaseFromJSONTyped(json, false);
}

export function CaseFromJSONTyped(json: any, ignoreDiscriminator: boolean): Case {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'contactFirstName': !exists(json, 'contactFirstName') ? undefined : json['contactFirstName'],
        'contactLastName': !exists(json, 'contactLastName') ? undefined : json['contactLastName'],
        'contactEmail': !exists(json, 'contactEmail') ? undefined : json['contactEmail'],
        'contactLanguage': !exists(json, 'contactLanguage') ? undefined : ContactLanguagesFromJSON(json['contactLanguage']),
        'attorneys': !exists(json, 'attorneys') ? undefined : (json['attorneys'] === null ? null : (json['attorneys'] as Array<any>).map(AssignedAttorneyFromJSON)),
        'id': !exists(json, 'id') ? undefined : json['id'],
        'clientId': !exists(json, 'clientId') ? undefined : json['clientId'],
        'firmId': !exists(json, 'firmId') ? undefined : json['firmId'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'shortTitle': !exists(json, 'shortTitle') ? undefined : json['shortTitle'],
        'caseNumber': !exists(json, 'caseNumber') ? undefined : json['caseNumber'],
        'judge': !exists(json, 'judge') ? undefined : json['judge'],
        'billingCode': !exists(json, 'billingCode') ? undefined : json['billingCode'],
        'jurisdiction': !exists(json, 'jurisdiction') ? undefined : json['jurisdiction'],
        'venue': !exists(json, 'venue') ? undefined : json['venue'],
        'trialDate': !exists(json, 'trialDate') ? undefined : json['trialDate'],
        'filedDate': !exists(json, 'filedDate') ? undefined : json['filedDate'],
        'caseType': !exists(json, 'caseType') ? undefined : json['caseType'],
        'damages': !exists(json, 'damages') ? undefined : json['damages'],
        'timeline': !exists(json, 'timeline') ? undefined : json['timeline'],
        'incidentDate': !exists(json, 'incidentDate') ? undefined : json['incidentDate'],
        'incidentTime': !exists(json, 'incidentTime') ? undefined : json['incidentTime'],
        'incidentLocation': !exists(json, 'incidentLocation') ? undefined : json['incidentLocation'],
        'incidentDescription': !exists(json, 'incidentDescription') ? undefined : json['incidentDescription'],
        'lastActivity': !exists(json, 'lastActivity') ? undefined : (json['lastActivity'] === null ? null : new Date(json['lastActivity'])),
        'tagIds': !exists(json, 'tagIds') ? undefined : json['tagIds'],
        'otherParties': !exists(json, 'otherParties') ? undefined : (json['otherParties'] === null ? null : (json['otherParties'] as Array<any>).map(PartyFromJSON)),
        'clientPosition': !exists(json, 'clientPosition') ? undefined : PartyPositionFromJSON(json['clientPosition']),
        'representativeType': !exists(json, 'representativeType') ? undefined : RepresentativeFromJSON(json['representativeType']),
        'defendants': !exists(json, 'defendants') ? undefined : json['defendants'],
        'plaintiffs': !exists(json, 'plaintiffs') ? undefined : json['plaintiffs'],
        'isArchived': !exists(json, 'isArchived') ? undefined : json['isArchived'],
        'clients': !exists(json, 'clients') ? undefined : json['clients'],
        'integration': !exists(json, 'integration') ? undefined : ExternalIntegrationFromJSON(json['integration']),
        'injuries': !exists(json, 'injuries') ? undefined : (json['injuries'] === null ? null : (json['injuries'] as Array<any>).map(CollectionItemFromJSON)),
        'agreements': !exists(json, 'agreements') ? undefined : (json['agreements'] === null ? null : (json['agreements'] as Array<any>).map(CollectionItemFromJSON)),
        'allegations': !exists(json, 'allegations') ? undefined : (json['allegations'] === null ? null : (json['allegations'] as Array<any>).map(CollectionItemFromJSON)),
        'causesOfAction': !exists(json, 'causesOfAction') ? undefined : (json['causesOfAction'] === null ? null : (json['causesOfAction'] as Array<any>).map(CollectionItemFromJSON)),
        'genericInfo': !exists(json, 'genericInfo') ? undefined : json['genericInfo'],
    };
}

export function CaseToJSON(value?: Case | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'contactFirstName': value.contactFirstName,
        'contactLastName': value.contactLastName,
        'contactEmail': value.contactEmail,
        'contactLanguage': ContactLanguagesToJSON(value.contactLanguage),
        'attorneys': value.attorneys === undefined ? undefined : (value.attorneys === null ? null : (value.attorneys as Array<any>).map(AssignedAttorneyToJSON)),
        'id': value.id,
        'clientId': value.clientId,
        'firmId': value.firmId,
        'title': value.title,
        'shortTitle': value.shortTitle,
        'caseNumber': value.caseNumber,
        'judge': value.judge,
        'billingCode': value.billingCode,
        'jurisdiction': value.jurisdiction,
        'venue': value.venue,
        'trialDate': value.trialDate,
        'filedDate': value.filedDate,
        'caseType': value.caseType,
        'damages': value.damages,
        'timeline': value.timeline,
        'incidentDate': value.incidentDate,
        'incidentTime': value.incidentTime,
        'incidentLocation': value.incidentLocation,
        'incidentDescription': value.incidentDescription,
        'lastActivity': value.lastActivity === undefined ? undefined : (value.lastActivity === null ? null : value.lastActivity.toISOString()),
        'tagIds': value.tagIds,
        'otherParties': value.otherParties === undefined ? undefined : (value.otherParties === null ? null : (value.otherParties as Array<any>).map(PartyToJSON)),
        'clientPosition': PartyPositionToJSON(value.clientPosition),
        'representativeType': RepresentativeToJSON(value.representativeType),
        'defendants': value.defendants,
        'plaintiffs': value.plaintiffs,
        'isArchived': value.isArchived,
        'clients': value.clients,
        'integration': ExternalIntegrationToJSON(value.integration),
        'injuries': value.injuries === undefined ? undefined : (value.injuries === null ? null : (value.injuries as Array<any>).map(CollectionItemToJSON)),
        'agreements': value.agreements === undefined ? undefined : (value.agreements === null ? null : (value.agreements as Array<any>).map(CollectionItemToJSON)),
        'allegations': value.allegations === undefined ? undefined : (value.allegations === null ? null : (value.allegations as Array<any>).map(CollectionItemToJSON)),
        'causesOfAction': value.causesOfAction === undefined ? undefined : (value.causesOfAction === null ? null : (value.causesOfAction as Array<any>).map(CollectionItemToJSON)),
        'genericInfo': value.genericInfo,
    };
}


