import { Modal } from 'components/Modals';
import { CheckLg, Copy, PencilFill } from 'react-bootstrap-icons';
import "./ReminderEmailModal.scss"
import { useAuth } from 'hooks/useAuth';
import { useClientApi, useContentApi, useCredentialApi } from 'hooks/useApi';
import { useEffect, useState } from 'react';
import { Client, CredentialResult, MiscContent } from 'briefpoint-client';
import { Button } from 'react-bootstrap';
import { EmailData } from 'screens/ClientInfo/SampleEmail';
import CustomEditor from 'components/Editor/CustomEditor';
import { replaceDictionary } from './utils';
import rg4js from 'raygun4js';
import { retrieveEmailContent } from 'utils/ClientFeedback';
import Loading from 'components/Loading';

interface Props {
  isShow: boolean;
  onClose: (success: boolean) => void;
  clientId: string | undefined;
  selectedClient: Client | undefined;
}

export default function ReminderEmailModal({ isShow, onClose, clientId, selectedClient }: Props) {
  const { ClipboardItem } = window;
  const { user, firm } = useAuth()!;
  const clientApi = useClientApi();
  const contentApi = useContentApi();
  const credentialApi = useCredentialApi();
  const [credential, setCredential] = useState<CredentialResult | undefined>();
  const [emailContent, setEmailContent] = useState<MiscContent | undefined>();
  const [editEmail, setEditEmail] = useState(false);
  const [isCopiedEmail, setIsCopiedEmail] = useState<boolean>(false);
  const [isEmailLoading, setIsEmailLoading] = useState<boolean>(false);
  const [emailBody, setEmailBody] = useState("");
  const [emailSubject, setEmailSubject] = useState("");
  const [saveTxt, setSaveTxt] = useState("Save as Template");

  useEffect(() => {
    const loadInitialData = async () => {
      if (!isShow || !clientId || !user || !selectedClient) return;

      if (!emailContent) {
        try {
          setIsEmailLoading(true);
          if (!credential) {
            const credentialData = await credentialApi.credentialCreate({ clientId });
            setCredential(credentialData);
          }

          if (selectedClient) {
            retrieveEmailContent(contentApi, selectedClient.contactLanguage, setEmailContent, setEmailBody, setEmailSubject);
          }

        } catch (error) {
          rg4js('send', {
            error: new Error(`Something went wrong when attempting to generate a reminder client email. ErrorData: {${error}}`),
          });
        }
        setIsEmailLoading(false);
      }
    };

    loadInitialData();
  }, [isShow, clientId, user, credential, selectedClient, emailContent, clientApi, credentialApi, contentApi]);

  useEffect(() => {
    if (isCopiedEmail) {
      const timer = setTimeout(() => {
        setIsCopiedEmail(false);
      }, 2000)
      return () => clearTimeout(timer);
    }
  }, [isCopiedEmail]);

  function handleClose() {
    onClose(true)
  }

  const handleEmailUpdate = async (emailBody:string) => {
    if(!editEmail){
      return;
    }
    setEmailBody(emailBody);
  };


  async function onUpdateTemplate(emailTemplate:EmailData){
    //create new
    if(emailContent && emailContent.name?.localeCompare("User_External_Reminder_Communication_Email")){
      await contentApi.contentPostMiscContent({
        miscContent:{...emailContent,
          name: "User_External_Reminder_Communication_Email",
          content: JSON.stringify(emailTemplate),
          id: undefined,
          ownerId: user?.id,
        }
      });
    }
    else if(emailContent){
      await contentApi.contentPutMiscContent({
        miscContent:{...emailContent,
          name: "User_External_Reminder_Communication_Email",
          content: JSON.stringify(emailTemplate),
        }
      });
    }
    setSaveTxt("Template Saved");
  } 

  function replaceWithDictionary(dictionary: { [key: string]: string }, text: string): string {
    let result = text;
  
    for (const key in dictionary) {
      if (dictionary.hasOwnProperty(key)) {
        const value = dictionary[key];
        const regex = new RegExp(`\\{\\{${key}\\}\\}`, 'g');
        result = result.replace(regex, value);
      }
    }
  
    return result;
  }

  function parseHtmlAsPlainText(html: string): string {
    let plainText = html.replace(/<li><div>/g, '\n    - ').replace(/<\/div><\/li>/g, '');
    plainText = plainText.replace(/<div>/g, '\n').replace(/<\/div>/g, '');
    plainText = plainText.replace(/<br>/g, '\n');
    plainText = plainText.replace(/<li>/g, '\n    - ').replace(/<\/li>/g, '');
    plainText = plainText.replace(/<[^>]*>/g, '');
    plainText = plainText.trim();
    return plainText;
  }
  
  const handleCopyClick = async (isCopiedEmailBtn?: boolean) => {
    try {
      await copyToClipboard(replaceWithDictionary(replaceDictionary(selectedClient, credential, user, firm),emailBody));
      if (isCopiedEmailBtn) {
        setIsCopiedEmail(true);
      }
    } catch (err) {
    }
  };

  //chrome
  const copyToClipboard = async (html: string) => {
    const blobHtml = new Blob([html], { type: "text/html" });
    const blobText = new Blob([html], { type: "text/plain" });
  
    try {
        const data = [new ClipboardItem({
          "text/plain": blobText,
          "text/html": blobHtml,
        })];
        await navigator.clipboard.write(data);
      }
    catch (err) {
      fallbackCopyToClipboard(html);
    }
  };
  
  //firefox
  const fallbackCopyToClipboard = (html: string) => {
    
    function listener(e:any) {
      e.clipboardData.setData("text/html", html);
      e.clipboardData.setData("text/plain", html);
      e.preventDefault();
    }
    document.addEventListener("copy", listener);
    document.execCommand("copy");
    document.removeEventListener("copy", listener);
  };
  
  const sendToEmailBody=encodeURIComponent(parseHtmlAsPlainText(replaceWithDictionary(replaceDictionary(selectedClient, credential, user, firm),emailBody)));

  function ModalContent() {

    if (isEmailLoading || !emailContent) {
      return (
        <>
          <Loading isLoading={isEmailLoading || !emailContent} text="Loading client email..." />
        </>
      )
    }

    return (
      <div className="email-container">
      <div className="button-group">
        <Button className="copy-button"
          onClick={() => handleCopyClick(true)}>
          {isCopiedEmail ? <><CheckLg className="check-icon" />Email Copied!</> : <> <Copy className="copy-icon" />Copy Email</>}
        </Button>
        <a href={`mailto:${selectedClient?.contactEmail}?subject=${encodeURIComponent(emailSubject)}&body=${sendToEmailBody}`} target="_blank" rel="noreferrer">
          <Button className="compose-button"
            onClick={() => handleCopyClick}>
            <PencilFill className="pencil-icon" />Open Email
          </Button>
        </a>
      </div>
      <div className="email-container">
        <div className="header">
        {editEmail && <Button 
            className="save-button"
            onClick={()=>{onUpdateTemplate({subject: emailSubject, body:emailBody})}}>
              {saveTxt}
          </Button>}
          <Button 
            className="save-button"
            onClick={()=>{setEditEmail(!editEmail)}}>
              {!editEmail? "Edit Email" : "Done Editing"}
          </Button>
        </div>
        <div className="subject">
          <b>Subject</b>
          <input type="text" 
            name="subject"
            value={emailSubject}
            onChange={(e)=>{setEmailSubject(e.target.value)}}
            readOnly={!editEmail} />
        </div>
        <CustomEditor 
          editorContent={editEmail?  emailBody :  replaceWithDictionary(replaceDictionary(selectedClient, credential, user, firm),emailBody)}
          handleChange={(e)=>{handleEmailUpdate(e)}}
          isDisabled={!editEmail}
          showMenu = {true}
          showAdvancedOptions = {true} 
          />
      </div>
      <Button 
        style={{maxWidth:"67px", marginTop:"18px", marginLeft:"auto"}}
        onClick={()=>{handleClose()}}> Close
        </Button>
    </div>
    );
  }

  return (
    <Modal
      show={isShow}
      onClose={handleClose}
      onConfirm={handleClose}
      size="lg"
      title={`Reminder Email with Custom Link and Login Code`}
      showCancelButton={false}
      showConfirmButton={false}
      preventBackgroundClick
    >
      {ModalContent()}
    </Modal>
  );
}
