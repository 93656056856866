/* tslint:disable */
/* eslint-disable */
/**
 * The Thing API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DocumentSubType,
    DocumentSubTypeFromJSON,
    DocumentSubTypeFromJSONTyped,
    DocumentSubTypeToJSON,
    DocumentType,
    DocumentTypeFromJSON,
    DocumentTypeFromJSONTyped,
    DocumentTypeToJSON,
    Languages,
    LanguagesFromJSON,
    LanguagesFromJSONTyped,
    LanguagesToJSON,
    MiscContentType,
    MiscContentTypeFromJSON,
    MiscContentTypeFromJSONTyped,
    MiscContentTypeToJSON,
    PropoundingParty,
    PropoundingPartyFromJSON,
    PropoundingPartyFromJSONTyped,
    PropoundingPartyToJSON,
} from './';

/**
 * 
 * @export
 * @interface MiscContent
 */
export interface MiscContent {
    /**
     * 
     * @type {string}
     * @memberof MiscContent
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof MiscContent
     */
    ownerId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MiscContent
     */
    sharedWithFirmId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MiscContent
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MiscContent
     */
    content?: string | null;
    /**
     * 
     * @type {MiscContentType}
     * @memberof MiscContent
     */
    type?: MiscContentType;
    /**
     * 
     * @type {DocumentType}
     * @memberof MiscContent
     */
    documentType?: DocumentType;
    /**
     * 
     * @type {DocumentSubType}
     * @memberof MiscContent
     */
    documentSubType?: DocumentSubType;
    /**
     * 
     * @type {string}
     * @memberof MiscContent
     */
    caseTypeId?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MiscContent
     */
    isDeleted?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MiscContent
     */
    isForRepresentative?: boolean | null;
    /**
     * 
     * @type {PropoundingParty}
     * @memberof MiscContent
     */
    respondentType?: PropoundingParty;
    /**
     * 
     * @type {number}
     * @memberof MiscContent
     */
    order?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MiscContent
     */
    jurisdictionId?: string | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof MiscContent
     */
    tagIds?: Array<number> | null;
    /**
     * 
     * @type {Languages}
     * @memberof MiscContent
     */
    language?: Languages;
}

export function MiscContentFromJSON(json: any): MiscContent {
    return MiscContentFromJSONTyped(json, false);
}

export function MiscContentFromJSONTyped(json: any, ignoreDiscriminator: boolean): MiscContent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'ownerId': !exists(json, 'ownerId') ? undefined : json['ownerId'],
        'sharedWithFirmId': !exists(json, 'sharedWithFirmId') ? undefined : json['sharedWithFirmId'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'content': !exists(json, 'content') ? undefined : json['content'],
        'type': !exists(json, 'type') ? undefined : MiscContentTypeFromJSON(json['type']),
        'documentType': !exists(json, 'documentType') ? undefined : DocumentTypeFromJSON(json['documentType']),
        'documentSubType': !exists(json, 'documentSubType') ? undefined : DocumentSubTypeFromJSON(json['documentSubType']),
        'caseTypeId': !exists(json, 'caseTypeId') ? undefined : json['caseTypeId'],
        'isDeleted': !exists(json, 'isDeleted') ? undefined : json['isDeleted'],
        'isForRepresentative': !exists(json, 'isForRepresentative') ? undefined : json['isForRepresentative'],
        'respondentType': !exists(json, 'respondentType') ? undefined : PropoundingPartyFromJSON(json['respondentType']),
        'order': !exists(json, 'order') ? undefined : json['order'],
        'jurisdictionId': !exists(json, 'jurisdictionId') ? undefined : json['jurisdictionId'],
        'tagIds': !exists(json, 'tagIds') ? undefined : json['tagIds'],
        'language': !exists(json, 'language') ? undefined : LanguagesFromJSON(json['language']),
    };
}

export function MiscContentToJSON(value?: MiscContent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'ownerId': value.ownerId,
        'sharedWithFirmId': value.sharedWithFirmId,
        'name': value.name,
        'content': value.content,
        'type': MiscContentTypeToJSON(value.type),
        'documentType': DocumentTypeToJSON(value.documentType),
        'documentSubType': DocumentSubTypeToJSON(value.documentSubType),
        'caseTypeId': value.caseTypeId,
        'isDeleted': value.isDeleted,
        'isForRepresentative': value.isForRepresentative,
        'respondentType': PropoundingPartyToJSON(value.respondentType),
        'order': value.order,
        'jurisdictionId': value.jurisdictionId,
        'tagIds': value.tagIds,
        'language': LanguagesToJSON(value.language),
    };
}


